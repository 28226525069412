export class ApiService {
  // Base function for API requests
  request = async (endpoint, method = "GET", body = null) => {
    // Request options
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (body) requestOptions.body = JSON.stringify(body);

    return fetch("https://bitlaunchstock.com/api/" + endpoint, requestOptions)
      .then(async (res) => {
        // Check if request was successfull
        if (!res.ok) {
          // Get error message
          const err = await res.text().then((error) => {
            return error;
          });

          console.error(err);

          throw err;
        }
        return res;
      })
      .catch((err) => {
        // Check if error is a string
        if (typeof err === typeof "") throw err;

        // eslint-disable-next-line no-throw-literal
        throw "something went wrong";
      });
  };

  // Get request
  get = (endpoint) => {
    return this.request(endpoint);
  };

  // Post request
  post = (endpoint, body) => {
    return this.request(endpoint, "POST", body);
  };
}
