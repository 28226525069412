import React from "react";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";

export default function StockRow({ stock, setCreateAlertOpts }) {
  // Handle the checkbox
  const handleChanged = (e) => {
    setCreateAlertOpts(stock.region_id, stock.size_id, e.target.checked);
  };

  return (
    <>
      <tr>
        <td>{stock.location}</td>
        <td>{stock.description}</td>
        <td>{stock.price}</td>
        <td>
          <Badge bg={stock.is_available ? "success" : "danger"}>
            {stock.is_available ? "Available" : "Unavailable"}
          </Badge>
        </td>
        <td>
          <Form.Check
            name={stock.region_id + stock.size_id}
            type="checkbox"
            label="Notify Me"
            onChange={handleChanged}
          />
        </td>
      </tr>
    </>
  );
}
