import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../apiService";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

export default function UpdateAlertHours() {
  const queryParams = new URLSearchParams(window.location.search);
  const [alertHours, setAlertHours] = useState([]);
  const token = queryParams.get("token");
  const navigate = useNavigate();
  const alertHoursRef = useRef();

  useEffect(() => {
    // Check if token exists
    if (!token) {
      navigate("/");
      return;
    }

    const apiService = new ApiService();

    // Get the available hours an alert can use
    apiService.get("alerthours").then(
      async (res) => {
        const data = await res.json();
        setAlertHours(data);
      },
      (err) => {}
    );

    // Unset state values when useEffect hook closes
    return () => {
      setAlertHours([]);
    };
  }, [token, navigate]);

  // Update the alert
  const updateAlert = () => {
    const apiService = new ApiService();
    const nextAlertHours = Number(alertHoursRef.current.value);

    // Setup request body
    const body = {
      next_alert_hours: nextAlertHours,
    };

    // Send request to update the alert
    apiService.post(`alerts/${token}/alerthours`, body).then(
      (res) => {
        toast.success(
          <>
            <h5>Alert Updated!</h5>
          </>
        );
      },
      (error) => {
        toast.error(
          <>
            <h5>Alert Update Failed</h5>
            {error}
          </>
        );
      }
    );
  };

  // Handle cancel button
  const handleCancel = () => {
    navigate("/");
  };

  return (
    <div className="text-center mt-4">
      <h3>Update Alert</h3>
      <Card className="mx-auto" style={{ maxWidth: "fit-content" }}>
        <Card.Body>
          <span style={{ marginRight: "1em" }}>
            Notify me as soon as stock is available but max once every
          </span>
          <Form.Select
            size="sm"
            style={{ maxWidth: "fit-content", display: "inline" }}
            ref={alertHoursRef}
          >
            {alertHours.map((hours) => {
              return (
                <option key={hours + "hours"} value={hours}>
                  {hours} Hours
                </option>
              );
            })}
          </Form.Select>

          <br />
          <br />

          <span className="text-muted">
            Note: The changes will take affect AFTER your next alert.
          </span>
        </Card.Body>
        <Card.Footer>
          <div className="row">
            <div className="col">
              <Button
                variant="secondary"
                onClick={handleCancel}
                style={{ width: "100%" }}
              >
                Cancel
              </Button>
            </div>
            <div className="col">
              <Button onClick={updateAlert} style={{ width: "100%" }}>
                Update
              </Button>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}
