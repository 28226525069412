import React, { useEffect, useState } from "react";
import StockRow from "./StockRow";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

export default function StockList({ stock, alerts, setAlerts }) {
  const [renderStock, setRenderStock] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  useEffect(() => {
    setRenderStock(stock);

    // Unset state values when useEffect hook closes
    return () => {
      setRenderStock([]);
      setSearchFilter({});
    };
  }, [stock]);

  // Handler for when search filter changes
  const handleSearchFilterChange = (e) => {
    const filter = searchFilter;
    filter[e.target.name] = e.target.value ? e.target.value : null;
    setSearchFilter(filter);

    // Check what stock should be rendered to the page
    const rStock = stock.filter((stockItem) => {
      let shouldRender = true;

      if (
        searchFilter.location &&
        searchFilter.location !== stockItem.region_id
      ) {
        shouldRender = false;
      }
      if (
        searchFilter.description &&
        searchFilter.description !== stockItem.size_id
      ) {
        shouldRender = false;
      }

      return shouldRender;
    });

    setRenderStock(rStock);
  };

  // Set the selected alerts to be notified for
  const setCreateAlertOpts = (regionID, sizeID, create) => {
    create
      ? setAlerts([...alerts, { region: regionID, size: sizeID }])
      : setAlerts(
          alerts.filter((alert) => {
            return !(alert.region === regionID && alert.size === sizeID);
          })
        );
  };

  return (
    <Table striped bordered hover size="sm">
      <thead className="fw-bold align-top">
        <tr>
          <td>
            <div className="row">
              <div className="col-12 col-lg">Location </div>
              <div className="col-12 col-lg">
                <Form.Select
                  size="sm"
                  name="location"
                  onChange={handleSearchFilterChange}
                >
                  <option value="">Select</option>
                  {stock
                    .filter(
                      (val, index, self) =>
                        index ===
                        self.findIndex((t) => t.region_id === val.region_id)
                    )
                    .map((stockItem) => {
                      return (
                        <option
                          key={"descriptionSearch" + stockItem.region_id}
                          value={stockItem.region_id}
                        >
                          {stockItem.location}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col-12 col-lg">Description </div>
              <div className="col-12 col-lg">
                <Form.Select
                  size="sm"
                  name="description"
                  onChange={handleSearchFilterChange}
                >
                  <option value="">Select</option>
                  {stock
                    .filter(
                      (val, index, self) =>
                        index ===
                        self.findIndex((t) => t.size_id === val.size_id)
                    )
                    .map((stockItem) => {
                      return (
                        <option
                          key={"descriptionSearch" + stockItem.size_id}
                          value={stockItem.size_id}
                        >
                          {stockItem.description}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
          </td>
          <td>Price</td>
          <td>Is Available</td>
          <td>Notify Me</td>
        </tr>
      </thead>
      <tbody>
        {renderStock
          .sort((a, b) => {
            return a.region_id.localeCompare(b.region_id, "en", {
              numeric: true,
            });
          })
          .map((stockItem) => {
            return (
              <StockRow
                key={stockItem.region_id + stockItem.size_id}
                stock={stockItem}
                setCreateAlertOpts={setCreateAlertOpts}
              />
            );
          })}
      </tbody>
    </Table>
  );
}
