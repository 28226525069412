import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApiService } from "./apiService";
import { toast } from "react-toastify";

export default function CreateAlertForm({ alerts }) {
  const [alertHours, setAlertHours] = useState([]);
  const emailRef = useRef();
  const alertHoursRef = useRef();

  useEffect(() => {
    const apiService = new ApiService();

    // Get available hours for an alert
    apiService.get("alerthours").then(
      async (res) => {
        const data = await res.json();
        setAlertHours(data);
      },
      (err) => {}
    );

    // Unset state values when useEffect hook closes
    return () => {
      setAlertHours([]);
    };
  }, []);

  // Send request to create a new alert
  const subscribe = (e) => {
    const apiService = new ApiService();
    const email = emailRef.current.value;
    const nextAlertHours = Number(alertHoursRef.current.value);

    if (alerts.length === 0) return;
    if (email === "") return;

    // Setup request body
    const body = {
      email: email,
      alerts: alerts,
      next_alert_hours: nextAlertHours,
    };

    // Send request
    apiService.post("alerts", body).then(
      (res) => {
        toast.success(
          <>
            <h5>Subscription Created</h5>
            Please check your email for a confirmation link.
          </>
        );
      },
      (error) => {
        toast.error(
          <>
            <h5>Subscription Failed</h5>
            {error}
          </>
        );
      }
    );
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-md-3 my-2 my-md-0">
          <Form.Label htmlFor="email">Email:</Form.Label>
          <Form.Control
            type="email"
            id="email"
            style={{ maxWidth: "fit-content", display: "inline" }}
            ref={emailRef}
            placeholder="example@email.com"
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 my-2 my-md-0">
          <span style={{ marginRight: "1em" }}>
            Notify me as soon as stock is available but max once every
          </span>
          <Form.Select
            size="sm"
            style={{ maxWidth: "fit-content", display: "inline" }}
            ref={alertHoursRef}
          >
            {alertHours.map((hours) => {
              return (
                <option key={hours + "hours"} value={hours}>
                  {hours} Hours
                </option>
              );
            })}
          </Form.Select>
        </div>

        <div className="col-12 col-sm-12 col-md-1 my-2 my-md-0">
          <Button variant="primary" onClick={subscribe} size="sm">
            Subscribe
          </Button>
        </div>
      </div>
    </>
  );
}
