import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../apiService";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

export default function Confirmation() {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate();
  const [confirmError, setConfirmError] = useState("");
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  useEffect(() => {
    // Check if token exists
    if (!token) {
      navigate("/");
      return;
    }

    const apiService = new ApiService();

    // Confirm the alerts
    apiService.post(`alerts/${token}/confirm`, {}).then(
      (res) => {
        setConfirmSuccess(true);
        setConfirmError("");
      },
      (err) => {
        setConfirmSuccess(false);
        setConfirmError(err);
      }
    );

    // Unset state values when useEffect hook closes
    return () => {
      setConfirmSuccess(false);
      setConfirmError("");
    };
  }, [token, navigate]);

  return (
    <div className="text-center mt-4">
      <h3>Subscription Confirmation</h3>
      {confirmSuccess && confirmError === "" ? (
        <Container>
          <Alert variant="success">
            <Alert.Heading>Success</Alert.Heading>
            You have successfully confirmed your subscription!
          </Alert>
        </Container>
      ) : !confirmSuccess && confirmError !== "" ? (
        <Container>
          <Alert variant="danger">
            <Alert.Heading>Error</Alert.Heading>
            <div>
              Something went wrong confirming your subscription, please try
              again or contact support.
            </div>
            <br />
            <div>Message: {confirmError}</div>
          </Alert>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
}
