import React from "react";
import Dashboard from "./pages/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import Image from "react-bootstrap/Image";
import Logo from "./assets/images/bitlaunch_stock.svg";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Confirmation from "./pages/Confirmation";
import Unsubscribe from "./pages/Unsubscribe";
import UpdateAlertHours from "./pages/UpdateAlertHours";

function App() {
  return (
    <>
      <div className="m-1 text-center">
        <Image src={Logo} height="65" />
      </div>
      <ToastContainer
        hideProgressBar={true}
        draggable={false}
        theme="colored"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/updatealert" element={<UpdateAlertHours />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
