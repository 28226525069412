import React, { useEffect, useState } from "react";
import { ApiService } from "../apiService";
import CreateAlertForm from "../CreateAlertForm";
import StockList from "../StockList";

export default function Dashboard() {
  const [stock, setStock] = useState([]);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const apiService = new ApiService();

    // Get the stock availability from the API
    apiService.get("stock").then(
      async (res) => {
        const data = await res.json();
        setStock(data);
      },
      (err) => {}
    );

    // Unset state values when useEffect hook closes
    return () => {
      setStock([]);
    };
  }, []);

  return (
    <>
      <div className="row m-4">
        <div className="col-12 mb-2">
          <CreateAlertForm alerts={alerts} />
        </div>
        <div className="col-12">
          <StockList stock={stock} alerts={alerts} setAlerts={setAlerts} />
        </div>
      </div>
    </>
  );
}
